export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const URL_STORAGE = {
  BASE_URL: 'https://jameswatt.nyc3.digitaloceanspaces.com/'
};
