/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  Trello as TrelloIcon,
  Layout as LayoutIcon,
  PieChart as PieChartIcon,
  Users as UsersIcon,
  BookOpen as BookOpenIcon,
  UserCheck as UserCheckIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const groups = {
  painel: {
    subheader: 'Painel',
    permissions: [ 'view_report']
  },
  gestao: {
    subheader: 'Gestão',
    permissions: [ 'view_inventory', 'view_layout', 'view_teamnr12' ]
  },
  treinamentos: {
    subheader: 'Treinamentos',
    permissions: [ 'view_trainings' ]
  },
  configuracoes: {
    subheader: 'Configurações',
    permissions: [ 'view_users' ]
  },
}

const sections = [
  {
    subheader: groups.painel.subheader,
    items: [
      {
        title: 'Relatórios',
        icon: PieChartIcon,
        permissions: 'view_report',
        href: '/app/reports/dashboard'
      },
    ]
  },
  {
    subheader: groups.gestao.subheader,
    items: [
      {
        title: 'Inventário',
        icon: TrelloIcon,
        permissions: 'view_inventory',
        href: '/app/management/inventory',
      },
      {
        title: 'Equipe NR-12',
        icon: UsersIcon,
        permissions: 'view_teamnr12',
        href: '/app/management/nr12team',
      },
      {
        title: 'Layout de fábrica',
        icon: LayoutIcon,
        permissions: 'view_layouts',
        href: '/app/management/layouts',
      },
    ]
  },
  {
    subheader: groups.treinamentos.subheader,
    items: [
      {
        title: 'Capacitações',
        icon: BookOpenIcon,
        permissions: 'view_trainings',
        items: [
          {
            title: 'Todas',
            permissions: 'view_trainings',
            href: '/app/trainings'
          },
          {
            title: 'Nova',
            permissions: 'view_trainings',
            href: '/app/trainings/create'
          },
          {
            title: 'Turmas',
            permissions: 'view_trainings',
            href: '/app/trainings/classes'
          },
        ],
      },
      {
        title: 'Ministrantes',
        icon: BriefcaseIcon,
        permissions: 'view_trainings',
        href: '/app/trainings/ministers',
      },
      {
        title: 'Alunos',
        icon: UserCheckIcon,
        permissions: 'view_trainings',
        href: '/app/trainings/students',
      },
      {
        title: 'Agenda',
        icon: CalendarIcon,
        permissions: 'view_trainings',
        href: '/app/trainings/agenda',
      }
    ]
  },
  {
    subheader: groups.configuracoes.subheader,
    items: [
      {
        title: 'Usuários',
        icon: UsersIcon,
        href: '/app/users',
        permissions: 'view_users',
        items: [
          {
            title: 'Todos',
            href: '/app/users',
            permissions: 'view_users',
          },
          {
            title: 'Pendentes',
            href: '/app/users/invitations',
            permissions: 'view_users',
          },
          {
            title: 'Novo usuário',
            href: '/app/users/new',
            permissions: 'view_users',
          },
        ]
      },
    ]
  },
];

const getSections = () => {
  const permissions = localStorage.getItem('@maquinasegura/permission').split(',');

  const intersection = permissions.map(p => 
    Object.values(groups).find(group => 
        group.permissions.find(permission => permission === p)
    )
  )
  
  const filteredIntersection = intersection.filter(group => typeof group !== 'undefined');
  const removeDuplicates = filteredIntersection.filter((a, b) => filteredIntersection.indexOf(a) === b);
  const newSections = sections.filter(n => removeDuplicates.find(t => n.subheader === t.subheader));

  return newSections;
}

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;
  const permissions = localStorage.getItem('@maquinasegura/permission');

  if(permissions.includes(item.permissions)) {
    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false
      });

      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavItem>
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
        />
      );
    }
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const newSections = getSections();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            pl={2}
            pt={3}
            pb={0}
            display="flex"
            // justifyContent="center"
          >
            <RouterLink to="/">
              <Logo type="horizontal2" />
            </RouterLink>
          </Box>
        </Hidden>
        {/* <Divider /> */}
        {/*<Box p={2}>
           <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            </RouterLink>
          </Box> */}
          {/* <Box
            mt={1}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="caption"
              color="textPrimary"
              underline="none"
            >
              {`${user.company.name} - ${factory.cod_inventario}`}
            </Link> */}
            {/* <Typography
              variant="body2"
              color="textSecondary"
            >
              <Link
                component={RouterLink}
                to="/"
              >
                {factory.cod_inventario}
              </Link>
            </Typography> 
          </Box>
        </Box>*/}
        {/* <Divider /> */}
        <Box p={2}>
          {newSections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
              </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Precisa de ajuda?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Veja a documentação
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
