import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    // alignItems: 'center',

    flex: '1 1 auto',
    overflow: 'auto',
    paddingTop: 64,

    [theme.breakpoints.up('lg')]: {
      paddingLeft: 200,
      paddingRight: 200,
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    // overflow: 'hidden'
  },
  content: {
    paddingTop: '50px',
    flex: '1 1 auto',
    height: '100%',
    // overflow: 'auto'
  },
  logo: {
    height: 50,
    opacity: 0.5,
  },
  rodape: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 50,
    paddingBottom: 50
  }
}));

function CompanyLayout({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
            <div className={classes.rodape}>
              <a href="http://www.jwengenharia.com.br" target="_blank" rel="noopener noreferrer">
                <img
                  alt="James Watt Engenharia"
                  className={classes.logo}
                  src={'/static/images/logo_gray.png'}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CompanyLayout.propTypes = {
  children: PropTypes.any
};

export default CompanyLayout;
