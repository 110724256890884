import React from 'react';



const Logo = (props) => {
  const types = [
    {
      type: 'horizontal1',
      src: '/static/logo_horiz_light.svg'
    },
    {
      type: 'horizontal2',
      src: '/static/logo_horiz_dark.svg'
    },
    {
      type: 'vertical',
      src: '/static/logo_vert.svg'
    },
    {
      type: 'mobile',
      src: '/static/logo_mobile.svg'
    },
  ]

  const brand = types.filter(t => props.type === t.type)[0];
  return (
    <img
      alt="Logo"
      src={brand.src}
      // src="/static/logo.svg"
      // {...props}
    />
  );
}

export default Logo;
