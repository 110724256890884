import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_URL,
  // timeout: 1000,
});

instance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default instance;
