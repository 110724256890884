import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

function PlantGuard({ children }) {
  const factory = localStorage.getItem('@maquinasegura/factory');
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!factory) {
    return <Redirect to="/app/corporate" />;
  }

  return (
    <>
      {children}
    </>
  );
}

PlantGuard.propTypes = {
  children: PropTypes.node
};

export default PlantGuard;
